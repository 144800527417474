import React from "react";
import { useDispatch } from "react-redux";
import {
  addToBasket,
  removeFromBasket,
  selectProductCountById,
  BasketProduct,
} from "../lib/redux/basketSlice";
import { useSelector } from "react-redux";
import { Add, Delete, Remove } from "../assets/icons/Icons";

const CartProduct: React.FC<BasketProduct> = ({
  product_id,
  product_images,
  product_name,
  product_price,
  product_details,
  product_description,
}) => {
  const dispatch = useDispatch();

  const addItemToBasket = () => {
    const product = {
      product_id,
      product_images,
      product_name,
      product_details,
      product_price,
      product_description,
    };
    dispatch(addToBasket(product));
  };

  const removeItemFromBasket = () => {
    const productToRemove = {
      product_id,
      product_images,
      product_name,
      product_details,
      product_price,
      product_description,
    }; // Create the complete object
    dispatch(removeFromBasket(productToRemove));
  };

  const itemCount = useSelector(selectProductCountById(product_id));
  const inflatedPrice = +(product_price * 1.3).toFixed(0);

  return (
    <div className="grid grid-cols-5 self-center">
      <img
        src={product_images[0]}
        alt={product_name}
        width={200}
        className="object-contain"
      />
      <div className="col-span-3 mx-5">
        <p className="font-semibold lg:text-xl">{product_name} </p>
        <ul className="list-disc p-5">
          {product_details.map((detail, index) => (
            <li key={index} className="text-sm lg:text-base">
              {detail}
            </li>
          ))}
        </ul>
        <p className="text-sm lg:text-base">
          <span className="line-through mr-2 md:mr-3">Rs. {inflatedPrice}</span>
          <span className="font-semibold text-base lg:text-lg ">
            Rs. {product_price}
          </span>
        </p>
      </div>
      <div className="flex flex-row items-center my-auto justify-self-end border-2 border-offWhite bg-offWhite rounded-lg">
        <button
          className="select-none text-black bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 h-7"
          onClick={addItemToBasket}
        >
          <Add />
        </button>
        <p className="flex justify-center px-4 ">{itemCount}</p>
        <button
          className={`select-none text-black bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 h-7 
          ${itemCount === 1 && "p-1"}`}
          onClick={removeItemFromBasket}
        >
          {itemCount === 1 ? <Delete /> : <Remove />}
        </button>
      </div>
    </div>
  );
};

export default CartProduct;
