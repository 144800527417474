import NavigationBar from "../../components/ui/NavBar";
import { Footer } from "../../components/Footer";

const Refund = () => {
  return (
    <>
      <NavigationBar />
      <div className="container lg:px-24 px-4 pt-[7rem] md:pt-32 text-justify">
        <p className="mt-1">Last Updated on August 28th, 2024</p>
        <p className="self-center whitespace-nowrap text-2xl font-semibold mt-5">
          Cancellation Policy
        </p>
        <p className="mt-3">
          These terms apply to any orders placed through our website,
          knotsofmacrame.com (the "Website"). Please review this policy
          carefully before making any purchases.
        </p>
        <p className="mt-7 font-semibold">Cancellation Timeframe:</p>
        <p>
          You may cancel your order at any time before it has been shipped. Once
          an order has been shipped, cancellations are not possible. Please
          contact us promptly if you wish to cancel your order before shipment.
        </p>
        <p className="mt-7 text-2xl font-semibold">Refund Policy</p>
        <p>
          Our refund policy covers returns, refunds, and exchanges for products
          purchased through our Website. By making a purchase, you agree to the
          terms outlined here.
        </p>
        <p className="mt-7 font-semibold">Refund Processing Time</p>
        <p>
          If your cancellation is processed before shipment, any payment made
          will be refunded to the original payment method within [X] business
          days. For products that have already been shipped, please refer to our
          return policy for further details.
        </p>
        <p className="mt-7 font-semibold">Non-Refundable Items:</p>
        <p>
          Certain items, such as custom or personalized products, may not be
          eligible for a refund. Please check the specific product page for any
          non-refundable conditions before purchase.
        </p>
        <p className="mt-5">
          Please note that the cancellation and refund policies mentioned above
          are specific to the products sold by Knots of Macrame through our
          Website. For any other services or situations, separate policies may
          apply.
        </p>
        <p className="mt-5">
          We value your satisfaction and strive to ensure transparency and
          efficiency in our processes. If you have any further questions or
          concerns regarding cancellations or refunds, please contact our
          customer support team at knotsofmacramee@gmail.com. We are here to
          assist you and provide the necessary guidance.
        </p>
        <p className="mt-5">
          By continuing to use our Website and making purchases, you acknowledge
          and accept the terms outlined in this cancellation and refund policy.
        </p>
        <p className="mt-5">
          Thank you for choosing Knots of Macrame for your home decor needs.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Refund;
