import React from "react";
import { Instagram } from "../assets/icons/Icons";

export const Footer = () => {
  return (
    <>
      <footer className="p-10 lg:px-20 lg:pt-12 mt-20 bg-darkBlue text-white w-full">
        <div className="lg:grid container mx-auto lg:px-4 lg:gap-8 xl:gap-0 lg:grid-cols-12 flex flex-col">
          <div className="lg:col-span-8">
            <div className="lg:mb-6 mb-10 md:mb-0 flex flex-col lg:w-2/3">
              <a href="/" className="flex items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/knots-of-macrame.appspot.com/o/logo.png?alt=media&token=a6794144-5d37-4e45-93b0-8f6bbf7075f4"
                  className="h-12 mb-5 rounded-lg"
                  alt="Knots of Macrame"
                />
              </a>
              <p className="mb-5 lg:w-3/5">
                <span className="text-sm leading-relaxed">
                  Handmade with Passion, Created with Love and Rooted in Culture
                </span>
              </p>
              <div className="flex flex-col">
                <p className="text-sm leading-relaxed">Made in India</p>
              </div>
              {/*
                <a
                  href="tel:+919886400292"
                  rel="noreferrer"
                  className="hover:text-gray-900"
                >
                  <Phone bg="#646A70" />
                  <span className="sr-only">Phone</span>
                </a>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@doctrue.in"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gray-900"
                >
                  <Mail bg="#646A70" />
                  <span className="sr-only">Mail</span>
                </a> <a
                  href="https://www.linkedin.com/company/doctrue/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gray-900"
                >
                  <LinkedIn bg="#646A70" />
                  <span className="sr-only">Linked In page</span>
                </a> 
                 <a
                  href="https://www.facebook.com/doctrue.in"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gray-900"
                >
                  <FaceBook bg="#646A70" />
                  <span className="sr-only">Facebook page</span>
                </a> 
                */}
            </div>
          </div>
          <div className="lg:col-span-4">
            <div className="flex flex-col lg:flex-row lg:text-right lg:items-end justify-start md:justify-end lg:mt-0 md:mt-5 mb-10">
              <p className="text-sm lg:text-lg uppercase mb-2 lg:mb-0">
                Follow us:
              </p>
              <a
                href="https://www.instagram.com/_knotsofmacrame_/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-400 ml-0 lg:ml-5"
              >
                <div className="flex flex-row items-end">
                  <Instagram bg="#646A70" />
                  <p className="ml-2">_knotsofmacrame_</p>
                </div>
                <span className="sr-only">Instagram account</span>
              </a>
            </div>
          </div>
        </div>
        <div className="lg:grid container mx-auto lg:px-5 lg:mt-16 lg:gap-8 xl:gap-0 lg:grid-cols-12 flex flex-col-reverse">
          <div className="lg:col-span-6">
            <a href="/" className="text-sm hover:underline">
              © {new Date().getFullYear()} Knots of Macrame
            </a>
          </div>
          <div className="lg:col-span-6">
            <div className="flex flex-col justify-between mb-5 lg:mb-0">
              <ul className="lg:flex lg:flex-wrap lg:gap-5 text-sm lg:justify-end">
                <li className="lg:mb-3 mb-1">
                  <a href="/privacy_policy" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/terms_of_use" className="hover:underline">
                    Terms of Use
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/contact_us" className="hover:underline">
                    Contact Us
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/refund_policy" className="hover:underline">
                    Refund Policy
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/shipping_policy" className="hover:underline">
                    Shipping Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <About modalVisibility={isModal} onCancel={() => setIsModal(false)} /> */}
      </footer>
      <hr className="border-orange border-4 w-full" />
    </>
  );
};
