import NavigationBar from "../../components/ui/NavBar";
import { Footer } from "../../components/Footer";

const Privacy = () => {
  return (
    <>
      <NavigationBar />
      <div className="container lg:px-24 px-4 pt-[7rem] md:pt-32 text-justify">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          Privacy Policy
        </p>
        <p className="mt-1">Last Updated on August 28th, 2024</p>
        <p className="mt-3">
          This Privacy Policy ("<text className="font-bold">Policy</text>")
          explains how Knots of Macrame ("KOM," "we," "us," or "our") collects,
          uses, shares, and protects personal information about you. This Policy
          applies to personal information we collect through our website, mobile
          applications, and other online services (collectively, the
          "Services").
        </p>
        <p className="mt-7 font-semibold text-xl">
          Collection of Personal Information
        </p>
        <p>
          We collect personal information from you when you register for an
          account on our app or website, fill out a form, or use our services.
          The types of personal information we collect may include:
        </p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li>
              <text className="font-bold">Location data: </text>
              <text>
                We collect your location data to facilitate service delivery and
                enhance your shopping experience. We do not share or sell this
                information to third parties.
              </text>
            </li>
            <li>
              <text className="font-bold">Contact Information: </text>
              <text>
                We collect your name, email, phone number, and other basic
                contact details to provide our Services, communicate with you,
                and tailor your experience.
              </text>
            </li>
            <li>
              <text className="font-bold">Demographic Information: </text>
              <text>
                We collect your gender and age to better understand our
                customers and improve our Services.
              </text>
            </li>
            <li>
              <text className="font-bold">
                Order and Transaction Information:{" "}
              </text>
              <text>
                We collect details related to your purchases, such as order
                history, payment information, and shipping details, to process
                and fulfill your orders.
              </text>
            </li>
          </ul>
        </div>
        <p className="mt-7 font-semibold text-xl">
          Use of Personal Information
        </p>
        <p>We use your personal information to:</p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li>
              <text className="font-bold">Provide our services to you: </text>
              <text>
                Utilize your information to process orders, deliver products,
                and offer customer support.
              </text>
            </li>
            <li>
              <text className="font-bold">Communicate with You: </text>
              <text>
                Contact you regarding your orders, updates, and, if you consent,
                marketing materials.
              </text>
            </li>
            <li>
              <text className="font-bold">Improve our services: </text>
              <text>
                Analyze usage patterns to refine and enhance our product
                offerings and customer experience.
              </text>
            </li>
            <li>
              <text className="font-bold">Personalize your experience: </text>
              <text>
                Customize your shopping experience by providing relevant product
                recommendations and offers.
              </text>
            </li>
            <li>
              <text className="font-bold">Comply with legal obligations: </text>
              <text>
                Use your information as needed to meet legal requirements,
                respond to legal requests, or prevent fraud.
              </text>
            </li>
          </ul>
          <p className="mt-3">
            We do not sell, lease, or share your personal information with third
            parties except as necessary to provide our Services. We may share
            your information with third-party service providers who assist us,
            such as payment processors, shipping companies, and customer support
            providers. These providers are restricted from using your
            information for purposes beyond providing services to us.
          </p>
        </div>
        <p className="mt-7 font-semibold text-xl">
          Protection of Personal Information
        </p>
        <p>
          We prioritize the security of your personal information and have
          implemented appropriate technical and organizational measures to
          protect it from unauthorized access, use, disclosure, or alteration.
        </p>
        <p className="mt-7 font-semibold text-xl">
          Retention of Personal Information
        </p>
        <p>
          We retain your personal information only as long as necessary to
          provide the Services you have requested or as required by law.
        </p>
        <p className="mt-7 font-semibold text-xl">Your Rights</p>
        <p>
          You have the right to access, modify, or delete your personal
          information at any time. To request a copy of your personal
          information or exercise other rights, please contact us at{" "}
          <a
            href="mailto:knotsofmacramee@gmail.com"
            className="underline text-blue-600"
          >
            knotsofmacramee@gmail.com
          </a>
          .
        </p>
        <p>
          If you believe your privacy rights have been violated, you may file a
          complaint with the relevant regulatory authority.
        </p>
        <p className="mt-7 font-semibold text-xl">
          Updates to this Privacy Policy
        </p>
        <p>
          We reserve the right to update or amend this Privacy Policy at any
          time. We encourage you to review this Policy regularly to stay
          informed about how we protect your personal information.
        </p>
        <p className="mt-7 font-semibold text-xl">Contact Us</p>
        <p>
          For any questions or concerns regarding our privacy practices, please
          reach out to us at{" "}
          <a
            href="mailto:knotsofmacramee@gmail.com"
            className="underline text-blue-600"
          >
            knotsofmacramee@gmail.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
