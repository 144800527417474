import NavigationBar from "../../components/ui/NavBar";
import Bedroom from "../../components/categories/Bedroom";
import { Footer } from "../../components/Footer";

const BedroomPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Bedroom />
      <Footer />
    </div>
  );
};

export default BedroomPage;
