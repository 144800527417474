import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { useDispatch } from "react-redux";
import {
  BasketProduct,
  emptyBasket,
  selectProducts,
  selectTotal,
} from "../lib/redux/basketSlice";
import { UserAuth } from "../lib/authContext";
import { Edit } from "../assets/icons/Icons";
import { createOrder, updateUser } from "../lib/apis";
import { toast } from "react-toastify";
import { isPhoneNumberValid, isPinCodeValid } from "../lib/funcs";
import CheckoutProduct from "../components/CheckoutProduct";
import { Footer } from "../components/Footer";
import NavigationBar from "../components/ui/NavBar";
import { komApiClient } from "../lib/apiClient";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectProducts);
  const subtotal = useSelector(selectTotal);
  const { user, userData, setUserData } = UserAuth();
  const [Razorpay] = useRazorpay();

  const [ph, setPh] = useState(userData?.phone_number);
  const [address, setAddress] = useState({
    houseNo: "",
    area: "",
    landmark: "",
    pinCode: "",
    city: "",
    state: "",
  });
  const [phvisible, setPhVisible] = useState(true);
  const [advisible, setAdVisible] = useState(true);
  const [discount, setDiscount] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddressChange = (field: string, value: string | undefined) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
  };

  const tax = ((subtotal / 2) * 0.17).toFixed(2);

  const total = Math.round(subtotal + Number(tax));

  // const razorpay_total = String(100);
  const razorpay_total = String(total * 100);

  const updatePhno = async () => {
    if (userData?.user_id && ph && isPhoneNumberValid(ph)) {
      const res = await updateUser(userData.user_id, ph);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        //@ts-ignore
        setUserData({
          ...userData,
          phone_number: ph,
        });
        setPhVisible(true);
      }
    }
  };

  const updateAddress = async () => {
    const { houseNo, area, landmark, pinCode, city, state } = address;

    // Create an array of address parts (excluding landmark from validation)
    const concatenatedAddress: string = [
      houseNo,
      area,
      landmark, // Including landmark in the final address, but not for validation
      pinCode,
      city,
      state,
    ]
      // Filter out undefined or empty strings
      .filter((part) => !!part)
      // Concatenate the filtered address parts with a comma and space
      .join(", ");

    // Validate required fields (houseNo, area, pinCode, city, state)
    if (!houseNo || !area || !pinCode || !city || !state) {
      toast.error("Please fill in all required address details");
      return;
    }

    if (!isPinCodeValid(pinCode)) {
      toast.error("Invalid pincode");
      return;
    }

    if (userData?.user_id && userData?.phone_number && address) {
      try {
        const res = await updateUser(
          userData.user_id,
          userData?.phone_number,
          concatenatedAddress
        );
        if (res?.status === 200) {
          toast.success("Updated successfully!");

          //@ts-ignore
          setUserData({
            ...userData,
            address: concatenatedAddress,
          });
          setAdVisible(true);
        }
      } catch (e) {
        console.error("Error saving address: ", e);
      }
    } else {
      toast.error("Please fill the required address details");
    }
  };

  const placeOrder = async (order_type: string, payment_id?: string) => {
    if (userData && userData.phone_number && userData.address && items) {
      setLoading(true);
      try {
        if (userData) {
          const order = {
            order_type: order_type,
            order_status: "pending",
            order_tax: tax,
            order_total: total,
            order_address: userData.address,
            user_name: userData.full_name,
            user_email: userData.email,
            user_phno: userData.phone_number,
            items: items.map((item) => item.product_id),
            user_id: userData?.user_id,
            payment_id: payment_id,
          };
          // console.log(order);
          const res = await createOrder(order);
          // console.log(res);
          if (res?.status === 201) {
            toast.success("Order placed successfully!");
            dispatch(emptyBasket());
            navigate("/order_placed");
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // set loading to false once the request is done
      }
    } else if (userData?.phone_number === null) {
      toast.error("Phone number is required.");
    } else if (userData?.address === null) {
      toast.error("Delivery Address is required.");
    }
  };

  const handlePayment = useCallback(async () => {
    console.log(razorpay_total);
    try {
      // Make the request to your backend to create a payment order
      const data = await komApiClient.post(`/pay`, {
        amount: razorpay_total,
      });

      if (data?.status !== 200) {
        throw new Error("Failed to create Razorpay order.");
      }

      const orderID = data.data.id;

      const options: RazorpayOptions = {
        key: "rzp_live_ak4WUtB7XVtLLm",
        amount: razorpay_total,
        currency: "INR",
        name: "Knots of Macrame",
        description: "Experience the charm of handcrafted macrame decor!",
        image:
          "https://firebasestorage.googleapis.com/v0/b/knots-of-macrame.appspot.com/o/logo.png?alt=media&token=a6794144-5d37-4e45-93b0-8f6bbf7075f4",
        order_id: orderID,
        handler: async (response) => {
          try {
            // Notify backend about the successful payment (you can skip this part if webhook does it automatically)
            const verifyResponse = await komApiClient.post("/pay-verify", {
              ...response,
              name: user?.displayName,
              email: user?.email,
              date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
              ph: userData?.phone_number,
              total: razorpay_total,
            });

            if (verifyResponse.status === 200) {
              // alert("Payment initiated. Waiting for confirmation.");
              placeOrder("paid", response.razorpay_payment_id);
            } else {
              console.error("Server error:", verifyResponse.statusText);
              alert("Failed to confirm payment with server.");
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            alert("Error verifying payment on the server.");
          }
        },
        theme: {
          color: "#000000",
        },
      };

      const rzpay = new Razorpay(options);

      rzpay.on("payment.failed", (response: any) => {
        console.error("Payment failed:", response.error);
        alert(`Payment failed: ${response.error.description}`);
      });

      rzpay.open();

      // WebSocket connection to listen for payment success
      // const ws = new WebSocket("ws://localhost:8080"); // Connect to your WebSocket server

      // ws.onmessage = (message) => {
      //   console.log(message);
      //   const parsedMessage = JSON.parse(message.data);
      //   if (
      //     parsedMessage.event === "payment_success" &&
      //     parsedMessage.order_id === orderID
      //   ) {
      //     console.log("yes");
      //     alert("Payment successful!");
      //     ws.close();
      //     // Close the Razorpay popup if it's still open
      //   }
      // };

      // ws.onclose = () => {
      //   console.log("WebSocket connection closed");
      // };
    } catch (error) {
      console.error("Error during payment initiation:", error);
      alert("There was an error initiating the payment. Please try again.");
    }
  }, [komApiClient, user, userData, razorpay_total, Razorpay]);

  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <section id="checkout" className="pt-[6rem] md:pt-32 my-auto container">
        <div className="flex flex-col lg:flex-row justify-evenly">
          {/* Left */}
          <div className="flex flex-col p-5 space-y-10 bg-white shadow-md">
            <h1 className="text-3xl border-b pb-4">Checkout</h1>
            {items
              .reduce(
                (acc: BasketProduct[], item) =>
                  acc.some((obj) => obj.product_id === item.product_id)
                    ? acc
                    : [...acc, item],
                []
              )
              .map((item, i) => (
                <CheckoutProduct
                  key={i}
                  product_id={item.product_id}
                  product_images={item.product_images}
                  product_name={item.product_name}
                  product_details={item.product_details}
                  product_description={item.product_description}
                  product_price={item.product_price}
                />
              ))}
          </div>

          {/* Right */}
          {items.length > 0 && (
            <div className="flex flex-col bg-white p-10 shadow-md mt-5 lg:mt-0">
              <p>Phone number</p>
              {phvisible ? (
                <div className="my-2 flex flex-row">
                  <input
                    value="+91"
                    className="text-grey2 rounded-xl p-2 w-12 mr-1"
                    disabled
                  />
                  <input
                    value={userData?.phone_number || ""}
                    className="text-grey2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-52"
                    disabled
                  />
                  <button
                    className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                    onClick={() => setPhVisible(false)}
                  >
                    <Edit />
                  </button>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="my-2 flex flex-row">
                    <input
                      value="+91"
                      className="text-grey2 rounded-xl p-2 w-12 mr-1"
                      disabled
                    />
                    <input
                      type="tel"
                      id="ph"
                      pattern="\d*"
                      placeholder="Enter your phone number"
                      value={ph}
                      onChange={(e) => setPh(e.target.value)}
                      className="rounded-xl max-w-sm w-64"
                      maxLength={10}
                      minLength={10}
                      autoFocus
                    />
                  </div>
                  <div className="flex flex-row justify-evenly mt-2">
                    <button
                      className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                      onClick={() => setPhVisible(true)}
                    >
                      Cancel
                    </button>
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                      onClick={updatePhno}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <p className="mt-5">Delivery Address</p>
              {advisible ? (
                <div className="flex flex-row">
                  <input
                    value={userData?.address || ""}
                    className="mt-2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-[260px] text-grey2"
                    disabled
                  />
                  <button
                    className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                    onClick={() => setAdVisible(false)}
                  >
                    <Edit />
                  </button>
                </div>
              ) : (
                <div className="flex flex-col">
                  <p className="font-semibold mt-3">
                    House no., Company, Apartment
                  </p>
                  <input
                    type="text"
                    id="address"
                    value={address.houseNo}
                    onChange={(e) =>
                      handleAddressChange("houseNo", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                    autoFocus
                    required
                  />
                  <p className="font-semibold">Area, Street, Sector, Village</p>
                  <input
                    type="text"
                    id="area"
                    value={address.area}
                    onChange={(e) =>
                      handleAddressChange("area", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                  />
                  <p className="font-semibold">Landmark</p>
                  <input
                    type="text"
                    id="landmark"
                    value={address.landmark}
                    onChange={(e) =>
                      handleAddressChange("landmark", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                  />
                  <p className="font-semibold">Pincode</p>
                  <input
                    type="text"
                    id="pincode"
                    value={address.pinCode}
                    onChange={(e) =>
                      handleAddressChange("pinCode", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                    maxLength={6}
                    required
                  />
                  <p className="font-semibold">Town/City</p>
                  <input
                    type="text"
                    id="city"
                    value={address.city}
                    onChange={(e) =>
                      handleAddressChange("city", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                    required
                  />
                  <p className="font-semibold">State</p>
                  <input
                    type="text"
                    id="state"
                    value={address.state}
                    onChange={(e) =>
                      handleAddressChange("state", e.target.value)
                    }
                    className="my-2 rounded-xl max-w-sm w-64"
                    required
                  />
                  <div className="flex flex-row justify-evenly mt-2">
                    <button
                      className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                      onClick={() => setAdVisible(true)}
                    >
                      Cancel
                    </button>
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                      onClick={updateAddress}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-between mt-10">
                <div className="flex flex-row items-center">
                  <h2>
                    Subtotal ({items.length}{" "}
                    {items.length > 1 ? "items" : "item"})
                  </h2>
                </div>
                <h2 className="">Rs. {(subtotal * 1.3).toFixed(2)}</h2>
              </div>

              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                  <h2>Discounted Price</h2>
                  <button
                    className="text-xs flex justify-center items-center border border-gray-500 rounded-full h-4 w-4 ml-1"
                    onClick={() => setDiscount(!discount)}
                  >
                    i
                  </button>
                </div>
                <h2 className="">Rs. {subtotal.toFixed(2)}</h2>
              </div>

              {discount && (
                <div className="bg-[#f6f6f6] rounded-xl p-2 my-1 lg:w-80">
                  <h2 className="text-sm text-center">
                    Congratulations! You are saving{" "}
                    <span className="font-semibold truncate">
                      Rs. {(subtotal * 0.3).toFixed(2)}
                    </span>{" "}
                    (30% discount) with this order!
                  </h2>
                </div>
              )}

              <div className="flex flex-row justify-between pb-2 border-b">
                <h2>Tax (GST)</h2>
                <h2 className="">Rs. {tax}</h2>
              </div>

              <div className="flex flex-row justify-between mt-2">
                <h2 className="font-bold text-lg ">Order Total</h2>{" "}
                <h2 className="font-bold text-lg ">Rs. {total}.00</h2>
              </div>

              <button
                disabled={loading}
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 mt-10"
                onClick={() => placeOrder("cod")}
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-black inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  "Cash on Delivery"
                )}
              </button>
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 mt-2"
                onClick={handlePayment}
              >
                <p>Pay with UPI</p>
              </button>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Checkout;
