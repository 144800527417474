import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../lib/authContext";
import NavigationBar from "../components/ui/NavBar";
import { Footer } from "../components/Footer";
import { BasketProduct } from "../lib/redux/basketSlice";
import Card from "../components/ProductCard";
import { komApiClient } from "../lib/apiClient";

const Wishlist = () => {
  const navigate = useNavigate();
  const { userData } = UserAuth();
  const [wishlist, setWishlist] = useState<BasketProduct[]>();

  const getItems = async () => {
    if (userData && userData.wishlist)
      try {
        const res = await komApiClient.post(`/kom-products/wishlist`, {
          productIds: userData.wishlist,
        });
        // console.log(res);
        if (res?.status === 200) {
          setWishlist(res.data);
        } else setWishlist(undefined);
      } catch (error) {
        console.log(error);
        setWishlist(undefined);
      }
  };

  useEffect(() => {
    getItems();
  }, [userData]);
  // console.log(wishlist);

  return (
    <main className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <section
        className="container lg:flex lg:justify-center my-auto py-[6rem] md:py-28 w-full"
        id="wishlist"
      >
        <div className="flex flex-col p-5 space-y-5 min-w-full">
          {wishlist === undefined ? (
            <>
              <h1 className="text-3xl border-b border-darkblue pb-4">
                You do not have any items in your wishlist.
              </h1>
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-52 self-center"
                onClick={() => navigate("/")}
              >
                Place an Order
              </button>
            </>
          ) : (
            <>
              <h1 className="text-3xl border-b border-darkblue pb-4">
                My Wishlist
              </h1>
              <div
                className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-5 xl:gap-10"
                id="plant"
              >
                <React.Fragment>
                  {wishlist?.map((item, index) => {
                    return <Card key={index} item={item} />;
                  })}
                </React.Fragment>
              </div>
            </>
          )}
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Wishlist;
