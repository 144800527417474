import NavigationBar from "../../components/ui/NavBar";
import Organizer from "../../components/categories/Organizer";
import { Footer } from "../../components/Footer";

const OrganizerPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Organizer />
      <Footer />
    </div>
  );
};

export default OrganizerPage;
