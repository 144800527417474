import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dropdownContent, navItems } from "../../assets/data/navbar";
import {
  Account,
  Bag,
  DownArrow,
  Minus,
  Plus,
  Search,
} from "../../assets/icons/Icons";
import { useSelector } from "react-redux";
import { selectProducts } from "../../lib/redux/basketSlice";
import ScrollingText from "./ScrollingText";
import CustomToggle from "./CustomToggle";
import SearchBar from "../SearchBar";
import ScrollToTop from "./ScrollToTop";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/images/kom_logo.webp";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const items = useSelector(selectProducts);

  const [currentNav, setCurrentNav] = useState("");
  const [hoveredNav, setHoveredNav] = useState("");
  const [isTogglerOpen, setTogglerOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [openMobileDropdown, setOpenMobileDropdown] = useState<string | null>(
    null
  );

  const handleMouseEnter = (item: string) => {
    setHoveredNav(item);
  };

  const handleMouseLeave = () => {
    setHoveredNav("");
  };

  const handleTogglerClick = () => {
    setTogglerOpen(!isTogglerOpen);
    setOpenMobileDropdown(null);
  };

  const handleNavItemToggle = (item: string) => {
    setOpenMobileDropdown(openMobileDropdown === item ? null : item);
  };

  useEffect(() => {
    const path = location.pathname.split("/categories/")[1];
    setCurrentNav(path);
  }, [location]);

  const renderDropdownContent = (item: string) => {
    return item === "Gifting" ? (
      <></>
    ) : (
      <ul className="bg-white px-2 py-3 shadow-xl w-60">
        {dropdownContent[item]?.map((dropdownItem, index) => (
          <li
            key={index}
            className={`py-1 px-2 cursor-pointer transform transition-transform duration-300 ease-in-out hover:translate-x-2 ${
              index + 1 === dropdownContent[item].length ? "" : "border-b"
            }`}
            onClick={() => navigate(`/collections/${dropdownItem.link}`)}
          >
            {dropdownItem.name}
          </li>
        ))}
      </ul>
    );
  };

  // New mobile-specific dropdown renderer
  const renderDropdownContentMobile = (item: string) => {
    return item === "Gifting" ? (
      <></>
    ) : (
      <ul className="bg-offWhite px-2 py-3 w-full">
        {dropdownContent[item]?.map((dropdownItem, index) => (
          <li
            key={index}
            className={`p-2 cursor-pointer uppercase text-sm ${
              index + 1 === dropdownContent[item].length ? "" : "border-b"
            }`}
            onClick={() => {
              navigate(`/collections/${dropdownItem.link}`);
              setTogglerOpen(false);
            }}
          >
            {dropdownItem.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="w-full">
      <ScrollingText />
      <nav
        id="NavMain"
        className="bg-white px-4 py-4 dark:border-gray-700 dark:bg-gray-800 sm:px-4 fixed top-8 w-full z-50 rounded-none shadow-md"
      >
        <div className="container mx-auto flex md:flex-wrap items-center justify-between">
          <div className="flex xl:hidden items-center space-x-4">
            {/* Custom toggle button */}
            <CustomToggle isOpen={isTogglerOpen} onClick={handleTogglerClick} />
            <button
              className="flex justify-center items-center focus:ring-0"
              onClick={() => {
                setSearchVisible && setSearchVisible((prev) => !prev);
              }}
            >
              <Search />
            </button>
          </div>

          <a
            href="/"
            className="flex items-center mx-2"
            onClick={() => {
              navigate(`/`);
              setCurrentNav("home");
              const elementToView = document.getElementById("home");
              elementToView?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <img
              src={logo}
              className="mr-2 md:mr-3 h-7 md:h-9 cursor-pointer rounded-md"
              alt="KOM Logo"
            />
            <p className="font-medium uppercase text-xs md:text-xl">
              Knots of Macrame
            </p>
          </a>

          <div className="flex items-center space-x-4 lg:order-2">
            <button
              className="hidden xl:flex focus:ring-0 hover:opacity-80 transition-opacity ease-in-out duration-300 md:mr-1"
              onClick={() =>
                setSearchVisible && setSearchVisible((prev) => !prev)
              }
            >
              <Search />
            </button>

            <a
              className="flex focus:ring-0 hover:opacity-80 transition-opacity ease-in-out duration-300 !ml-0 md:!ml-4"
              href="/account"
              onClick={() => {
                navigate("/account");
                setCurrentNav("account");
              }}
            >
              <Account />
            </a>

            <a
              className="flex focus:ring-0 hover:opacity-80 transition-opacity ease-in-out duration-300 relative !mr-3"
              href="/cart"
              onClick={() => {
                navigate("/cart");
                setCurrentNav("/cart");
              }}
            >
              <Bag />
              <span className="absolute top-[-5px] left-[20px] h-4 w-4 bg-cyan-200 justify-center rounded-full text-black text-center text-xs">
                {items?.length}
              </span>
            </a>
          </div>

          <div className="hidden xl:flex space-x-5">
            {navItems.map((item, index) => (
              <div
                key={index}
                className="relative"
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  href={`/categories/${item.link}`}
                  onClick={() => setCurrentNav(item.name)}
                  className={`flex items-center md:hover:!text-cyan-600 transition-colors ease-in-out duration-300 uppercase ${
                    currentNav === item.link ? "!text-cyan-600" : "!text-black"
                  }`}
                >
                  {item.name}
                  {item.name !== "Gifting" && <DownArrow />}
                </a>

                {hoveredNav === item.name && (
                  <div className="absolute top-full left-0 animate-slideDownFadeIn">
                    {renderDropdownContent(item.name)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Mobile Menu */}
        {isTogglerOpen && (
          <div className="block xl:hidden pt-5 animate-slideDownFadeIn">
            {navItems.map((item, index) => (
              <div
                key={index}
                className={`${
                  navItems.length === index + 1 ? "" : "border-b"
                } flex flex-col items-center`}
              >
                <div className="flex flex-row justify-between w-full p-2">
                  <a
                    href={item.link === "" ? "/" : `/categories/${item.link}`}
                    onClick={() => setCurrentNav(item.name)}
                    className={`text-sm cursor-pointer md:hover:!text-cyan-600 transition-colors ease-in-out duration-300 uppercase ${
                      currentNav === item.link
                        ? "!text-cyan-600"
                        : "!text-black"
                    }`}
                  >
                    {item.name}
                  </a>
                  {item.name !== "Gifting" && (
                    <button onClick={() => handleNavItemToggle(item.name)}>
                      {item.name === openMobileDropdown ? <Minus /> : <Plus />}
                    </button>
                  )}
                </div>

                {/* Render mobile dropdown if toggled */}
                {openMobileDropdown === item.name && (
                  <div className="w-full left-0 animate-slideDownFadeIn">
                    {renderDropdownContentMobile(item.name)}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </nav>
      {searchVisible && <SearchBar setSearchVisible={setSearchVisible} />}
      <ScrollToTop />

      <FloatingWhatsApp
        phoneNumber="7795524743"
        accountName="Knots of Macrame"
        statusMessage="Online"
        avatar={logo}
        chatMessage="Add a touch of elegance to your space today! 🧶✨"
        messageDelay={1}
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
  );
};

export default NavigationBar;
