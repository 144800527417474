import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../lib/authContext";
import { OrderData } from "../lib/types";
import NavigationBar from "../components/ui/NavBar";
import Order from "../components/Order";
import { Footer } from "../components/Footer";
import { BasketProduct } from "../lib/redux/basketSlice";
import { fetchOrders } from "../lib/apis";

const Orders = () => {
  const navigate = useNavigate();
  const { userData } = UserAuth();
  const [ordersData, setOrdersData] = useState<OrderData[]>();

  const getOrders = useCallback(async () => {
    if (userData?.user_id) {
      const res = await fetchOrders(userData?.user_id);
      console.log(res);
      if (res?.status === 200) setOrdersData(res.data);
    }
  }, [userData]);

  useEffect(() => {
    if (!ordersData || ordersData.length === 0) {
      getOrders();
    }
  }, [getOrders]);

  useEffect(() => {
    getOrders();
  }, [userData]);

  return (
    <main className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <section
        className="lg:flex my-auto lg:mx-auto py-[6rem] md:pt-[8rem] md:28 justify-evenly"
        id="orders"
      >
        <div className="flex flex-col p-5 space-y-5 bg-white shadow-md">
          {ordersData === undefined ? (
            <>
              <h1 className="text-3xl border-b pb-4">
                You do not have any orders.
              </h1>
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                onClick={() => navigate("/")}
              >
                Place an Order
              </button>
            </>
          ) : (
            <h1 className="text-3xl border-b pb-4">My Orders</h1>
          )}
          {ordersData === undefined ? (
            <></>
          ) : (
            ordersData?.map((order) => {
              // Create a Map to store unique items by product_id
              const uniqueItemsMap = new Map();

              order.items.forEach((item) => {
                if (!uniqueItemsMap.has(item.product_id)) {
                  uniqueItemsMap.set(item.product_id, item);
                }
              });

              // Convert the Map back to an array of unique items
              const uniqueItems: BasketProduct[] = Array.from(
                uniqueItemsMap.values()
              );
              return (
                <React.Fragment key={order.order_id}>
                  <p>Order ID: {order.order_id}</p>
                  <p className="mt-0">
                    Order placed:{" "}
                    {new Date(order.created_at).toLocaleDateString()}
                  </p>
                  <p>Delivery address: {order.order_address}</p>
                  {uniqueItems.map((item) => (
                    <Order
                      key={item.product_id}
                      product_id={item.product_id}
                      product_name={item.product_name}
                      product_images={item.product_images}
                      product_price={item.product_price}
                      product_details={item.product_details}
                      itemCount={
                        order.items.filter(
                          (i) => i.product_id === item.product_id
                        ).length
                      }
                    />
                  ))}
                  <div>
                    <p>
                      Payment:{" "}
                      {order.order_type === "cod"
                        ? "Cash on Delivery"
                        : "Paid using UPI"}
                    </p>
                    {order.payment_id && <p>Payment ID: {order.payment_id}</p>}
                    <p>
                      Subtotal:{" "}
                      <span className="">
                        Rs. {(order.order_total - order.order_tax).toFixed(2)}
                      </span>
                    </p>
                    <p>
                      Tax: <span className="">Rs. {order.order_tax}</span>
                    </p>
                    <p>
                      Total:{" "}
                      <span className="font-bold text-lg ">
                        Rs. {order.order_total}
                      </span>
                    </p>
                    <p className="border-b"></p>
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Orders;
