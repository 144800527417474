import NavigationBar from "../../components/ui/NavBar";
import Living from "../../components/categories/Living";
import { Footer } from "../../components/Footer";

const LivingPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Living />
      <Footer />
    </div>
  );
};

export default LivingPage;
