import React from "react";
import NavigationBar from "../components/ui/NavBar";
import { Footer } from "../components/Footer";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { Location, ContactMail, ContactPhone } from "../assets/icons/Icons";

const ContactUs = () => {
  const markerLocation = {
    lat: 12.9992431,
    lng: 77.6131703,
  };

  return (
    <div className="flex flex-col items-center bg-[#f6f6f6]">
      <NavigationBar />
      <div
        id="contact-us"
        className="min-h-screen py-20 md:py-32 px-3 container"
      >
        <div className="flex flex-col rounded-xl p-10 bg-cyan-50 border border-[#5A495B80] md:mx-5 my-10 lg:my-20">
          <h1 className="self-center whitespace-nowrap text-lg lg:text-xl text-pureGold font-semibold uppercase">
            Contact Us
          </h1>

          <div className="flex flex-col space-y-5">
            <p className="text-xl font-medium mt-10">Operating Office</p>
            <div className="flex flex-row items-center">
              <p className="mr-2">
                <Location />
              </p>
              <a
                href="https://maps.app.goo.gl/foQ7d7TQtVBsaG267"
                target="_blank"
                rel="noreferrer"
                className="hover:text-cyan-500"
              >
                Rahamans Grace 19, Mosque Rd, above Sangeeta showroom, Pulikeshi
                Nagar, Bengaluru, Karnataka 560005
              </a>
            </div>
            <div className="flex flex-row items-center">
              <p className="mr-2">
                <ContactPhone fill="#000" />
              </p>
              <a href="tel:+917795524743" className="hover:text-cyan-500">
                +91 77955 24743
              </a>
            </div>
            <div className="flex flex-row items-center">
              <p className="mr-2">
                <ContactMail fill="#000" />
              </p>
              <a
                href="mailto:info@zzeeh.com"
                target="_blank"
                rel="noreferrer"
                className="hover:text-cyan-500"
              >
                info@zzeeh.com
              </a>
            </div>
          </div>
        </div>

        <div className="h-80 xl:h-96 w-full md:px-10 mt-10 lg:mt-20">
          <APIProvider apiKey="AIzaSyCuRS5oyHEEK81_qDXv10fQc3vZB0xmC1M">
            <Map
              mapId="1b3b9ffa5d4fd0ab"
              defaultZoom={13}
              defaultCenter={markerLocation}
              gestureHandling={"greedy"}
            >
              <AdvancedMarker position={markerLocation} />
            </Map>
          </APIProvider>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
