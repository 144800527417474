import NavigationBar from "../../components/ui/NavBar";
import Balcony from "../../components/categories/Balcony";
import { Footer } from "../../components/Footer";

const BalconyPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Balcony />
      <Footer />
    </div>
  );
};

export default BalconyPage;
