import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BasketProduct {
  product_id: string;
  product_images: Array<string>;
  product_name: string;
  product_details: Array<string>;
  product_description?: string;
  product_price: number;
  product_category?: string;
  product_subcategory?: string;
}

export interface BasketState {
  Products: BasketProduct[];
}

const initialState: BasketState = {
  Products: [],
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<BasketProduct>) => {
      state.Products.push(action.payload);
    },
    removeFromBasket: (state, action: PayloadAction<BasketProduct>) => {
      const index = state.Products.findIndex(
        (basketProduct) =>
          basketProduct.product_id === action.payload.product_id
      );

      if (index >= 0) {
        state.Products.splice(index, 1);
      } else {
        console.warn(
          `Cannot remove product (id: ${action.payload.product_id}) as it is not in the cart`
        );
      }
    },
    emptyBasket: (state) => {
      state.Products = []; // Removes everything from the basket
    },
  },
});

export const { addToBasket, removeFromBasket, emptyBasket } =
  basketSlice.actions;

export const selectProducts = (state: { basket: BasketState }) =>
  state.basket.Products;

export const selectTotal = (state: { basket: BasketState }) =>
  state.basket.Products.reduce(
    (total, Product) => total + Number(Product.product_price),
    0
  );

export const selectProductCountById =
  (ProductId: string) => (state: { basket: BasketState }) =>
    state.basket.Products.filter((Product) => Product.product_id === ProductId)
      .length;

export default basketSlice.reducer;
