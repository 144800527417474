import React from "react";
interface Props {
  product_id: string;
  product_images: Array<string>;
  product_name: string;
  product_details: Array<string>;
  product_price: number;
  itemCount?: number;
}

const Order: React.FC<Props> = ({
  product_id,
  product_images,
  product_name,
  product_details,
  product_price,
  itemCount,
}) => {
  return (
    <div className="grid grid-cols-5 self-center">
      <img
        src={product_images[0]}
        alt={product_name}
        width={200}
        className="object-contain"
      />
      <div className="col-span-3 mx-5">
        <p className="font-semibold text-lg lg:text-xl">{product_name} </p>

        <ul className="list-disc p-5">
          {product_details.map((detail, index) => (
            <li key={index} className="text-sm lg:text-base">
              {detail}
            </li>
          ))}
        </ul>
        {/* <p className="text-sm lg:text-base">
          M.R.P.:{" "}
          <span className="font-bold text-base lg:text-lg ">
            Rs. {product_price}
          </span>
        </p> */}
        {itemCount && itemCount > 1 && (
          <p className="text-sm lg:text-base">
            Quantity:{" "}
            <span className="font-bold text-base lg:text-lg ">{itemCount}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Order;
