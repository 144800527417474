import React, { useState } from "react";
import { UserAuth } from "../lib/authContext";
import GoogleButton from "react-google-button";
import icon from "../assets/images/icon.png";
import NavigationBar from "../components/ui/NavBar";
import { useNavigate } from "react-router-dom";
import { Edit } from "../assets/icons/Icons";
import { isPhoneNumberValid, isPinCodeValid } from "../lib/funcs";
import { updateUser } from "../lib/apis";
import { toast } from "react-toastify";
import { Footer } from "../components/Footer";

const Account = () => {
  const { user, userData, setUserData, googleSignIn, signOut } = UserAuth();
  // console.log(userData);
  const navigate = useNavigate();
  const [ph, setPh] = useState(userData?.phone_number);
  const [address, setAddress] = useState({
    houseNo: "",
    area: "",
    landmark: "",
    pinCode: "",
    city: "",
    state: "",
  });
  const [phvisible, setPhVisible] = useState(true);
  const [advisible, setAdVisible] = useState(true);

  const handleAddressChange = (field: string, value: string | undefined) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
  };

  const updatePhno = async () => {
    if (userData?.user_id && ph && isPhoneNumberValid(ph)) {
      const res = await updateUser(userData?.user_id, ph);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        //@ts-ignore
        setUserData({
          ...userData,
          phone_number: ph,
        });
        setPhVisible(true);
      }
    }
  };

  const updateAddress = async () => {
    const { houseNo, area, landmark, pinCode, city, state } = address;

    // Create an array of address parts (excluding landmark from validation)
    const concatenatedAddress: string = [
      houseNo,
      area,
      landmark, // Including landmark in the final address, but not for validation
      pinCode,
      city,
      state,
    ]
      // Filter out undefined or empty strings
      .filter((part) => !!part)
      // Concatenate the filtered address parts with a comma and space
      .join(", ");

    // Validate required fields (houseNo, area, pinCode, city, state)
    if (!houseNo || !area || !pinCode || !city || !state) {
      toast.error("Please fill in all required address details");
      return;
    }

    if (!isPinCodeValid(pinCode)) {
      toast.error("Invalid pincode");
      return;
    }

    if (userData?.user_id && userData?.phone_number && address) {
      try {
        const res = await updateUser(
          userData.user_id,
          userData?.phone_number,
          concatenatedAddress
        );
        if (res?.status === 200) {
          toast.success("Updated successfully!");

          //@ts-ignore
          setUserData({
            ...userData,
            address: concatenatedAddress,
          });
          setAdVisible(true);
        }
      } catch (e) {
        console.error("Error saving address: ", e);
      }
    } else {
      toast.error("Please fill the required address details");
    }
  };

  return (
    <>
      <NavigationBar />
      <main>
        <div
          className="lg:flex max-w-screen-2xl mx-auto pt-[6rem] md:pt-32 justify-evenly"
          id="account"
        >
          {/* Left */}
          <div className="flex flex-col p-5 space-y-10 bg-white shadow-md">
            <h1 className="text-3xl border-b pb-4">My Account</h1>
            {user ? (
              <div className="grid grid-cols-5 self-center pb-10">
                <img
                  src={user?.photoURL ? user.photoURL : icon}
                  className="mb-10 mr-5 object-contain rounded-full"
                  height={250}
                  alt={user?.displayName || ""}
                />
                <div className="col-span-5 lg:col-span-4 mx-2">
                  <div className="flex flex-row items-center mb-5">
                    <p>Name:</p>
                    <input
                      //@ts-ignore
                      value={user ? user.displayName : ""}
                      className="ml-2 rounded-xl p-2 w-60"
                      disabled
                    />
                  </div>
                  <div className="flex flex-row items-center mb-5">
                    <p>Email:</p>
                    <input
                      value={userData?.email}
                      className="ml-2 rounded-xl p-2 w-60"
                      disabled
                    />
                  </div>

                  <p>Phone number</p>
                  {phvisible ? (
                    <div className="my-2 flex flex-row">
                      <input
                        value="+91"
                        className="text-grey2 rounded-xl p-2 w-12 mr-1"
                        disabled
                      />
                      <input
                        value={userData?.phone_number || ""}
                        className="text-grey2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-52"
                        disabled
                      />
                      <button
                        className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                        onClick={() => setPhVisible(false)}
                      >
                        <Edit />
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="my-2 flex flex-row">
                        <input
                          value="+91"
                          className="text-grey2 rounded-xl p-2 w-12 mr-1"
                          disabled
                        />
                        <input
                          type="tel"
                          id="ph"
                          pattern="\d*"
                          placeholder="Enter your phone number"
                          value={ph}
                          onChange={(e) => setPh(e.target.value)}
                          className="rounded-xl max-w-sm w-64"
                          maxLength={10}
                          minLength={10}
                          autoFocus
                        />
                      </div>
                      <div className="flex flex-row space-x-10 mt-2">
                        <button
                          className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                          onClick={() => setPhVisible(true)}
                        >
                          Cancel
                        </button>
                        <button
                          className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                          onClick={updatePhno}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}

                  <p className="mt-5">Delivery Address</p>
                  {advisible ? (
                    <div className="flex flex-row">
                      <input
                        value={userData?.address || ""}
                        className="mt-2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-[260px] text-grey2"
                        disabled
                      />
                      <button
                        className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                        onClick={() => setAdVisible(false)}
                      >
                        <Edit />
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <p className="font-semibold mt-3">
                        House no., Company, Apartment
                      </p>
                      <input
                        type="text"
                        id="address"
                        value={address.houseNo}
                        onChange={(e) =>
                          handleAddressChange("houseNo", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                        autoFocus
                        required
                      />
                      <p className="font-semibold">
                        Area, Street, Sector, Village
                      </p>
                      <input
                        type="text"
                        id="area"
                        value={address.area}
                        onChange={(e) =>
                          handleAddressChange("area", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                      />
                      <p className="font-semibold">Landmark</p>
                      <input
                        type="text"
                        id="landmark"
                        value={address.landmark}
                        onChange={(e) =>
                          handleAddressChange("landmark", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                      />
                      <p className="font-semibold">Pincode</p>
                      <input
                        type="text"
                        id="pincode"
                        value={address.pinCode}
                        onChange={(e) =>
                          handleAddressChange("pinCode", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                        maxLength={6}
                        required
                      />
                      <p className="font-semibold">Town/City</p>
                      <input
                        type="text"
                        id="city"
                        value={address.city}
                        onChange={(e) =>
                          handleAddressChange("city", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                        required
                      />
                      <p className="font-semibold">State</p>
                      <input
                        type="text"
                        id="state"
                        value={address.state}
                        onChange={(e) =>
                          handleAddressChange("state", e.target.value)
                        }
                        className="my-2 rounded-xl max-w-sm w-64"
                        required
                      />
                      <div className="flex flex-row space-x-10 mt-2">
                        <button
                          className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                          onClick={() => setAdVisible(true)}
                        >
                          Cancel
                        </button>
                        <button
                          className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                          onClick={updateAddress}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col space-y-10 items-center justify-center p-10 visible lg:hidden">
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                      onClick={() => navigate("/orders")}
                    >
                      My Orders
                    </button>
                    {/* <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                      onClick={() => navigate("/quotes")}
                    >
                      My Quotes
                    </button> */}
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                      onClick={() => navigate("/wishlist")}
                    >
                      My Wishlist
                    </button>
                    <button
                      className="text-white bg-red-500 hover:bg-red-700 focus:ring-0 select-none px-3 py-2 rounded-md focus:outline-none w-full"
                      onClick={signOut}
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <GoogleButton onClick={googleSignIn} />
            )}
          </div>

          {/* Right */}
          {user && (
            <div className="lg:flex lg:flex-col space-y-10 items-center justify-center bg-white p-10 shadow-md mt-5 lg:mt-0 md:visible hidden">
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                onClick={() => navigate("/orders")}
              >
                My Orders
              </button>
              {/* <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                onClick={() => navigate("/quotes")}
              >
                My Quotes
              </button> */}
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                onClick={() => navigate("/wishlist")}
              >
                My Wishlist
              </button>
              <button
                className="text-white bg-red-500 hover:bg-red-700 focus:ring-0 select-none px-3 py-2 rounded-md focus:outline-none w-full"
                onClick={signOut}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Account;
